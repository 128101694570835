* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  user-select: none;
  font-family: Inter, sans-serif;
}

.ant-btn {
  font-family: Inter, sans-serif !important;
}

.ant-select-selection-item {
  font-family: Inter, sans-serif !important;
}

#root {
  width: 100%;
  min-height: 100vh;
  background: #fdfdfd;
}

.main-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 80px 20px;
}
