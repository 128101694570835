.event-form {
  display: flex;
  flex-direction: column-reverse;
}

.event-form-row-holder {
  gap: 0;
  order: 2;
  margin: 0 0 24px 0;
  border-radius: 12px;
  flex-direction: column;
  border: 1px solid #F0F0F0;

  > .ant-space-item {
    width: 100%;
  }
}

.form-row-modules {
  gap: 30px;
  height: 64px;
  display: flex;
  padding: 0 24px;
  background: white;
  align-items: center;
  border-radius: 12px;

  > .ant-form-item {
    max-width: 236px;
  }
}

.form-row-modules > div:not(.connection-symbol) {
  width: 100%;
}

.form-row-modules > .ant-form-item {
  margin: 0;
}

.connection-symbol {
  height: 2px;
  width: 18px;
  position: relative;
  border-radius: 2px;
  background-color: #4D85FF;
}

.connection-symbol:after,
.connection-symbol:before {
  top: -50%;
  width: 4px;
  height: 4px;
  content: '';
  border-radius: 50%;
  position: absolute;
  background-color: #4D85FF;
}

.connection-symbol:before {
  left: -6px;
}

.connection-symbol:after {
  right: -6px;
}

.event-form .ant-select-selector {
  background: #F5F5F5 !important;
}

.ant-form-item-control {
  position: relative;

  > div:nth-child(2):last-child {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, -100%);
  }
}

.ant-form-item-control .ant-form-item-explain-error {
  padding: 0 12px;
  background: white;
}

.row-delete-button {
  width: 40px;
  background: #F5F5F5 !important;
}

.add-event-holder  {
  order: 3;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .add-icon-holder {
      > svg {
        > path {
          transition: all 300ms;
        }
      }
    }

    &:hover {
      .add-icon-holder {
        > svg {
          > path {
            stroke: #4096ff;
            transition: all 300ms;
          }
        }
      }
    }

    .add-icon-holder {
      display: flex;
      margin-inline-end: 8px;
    }
  }
}

.save-button-holder {
  top: 0;
  right: 0;
  position: absolute;
}

.select-with-close-wrapper {
  gap: 8px;
  display: flex;
  width: 236px !important;

  .ant-select {
    max-width: 188px;
  }

  > .ant-form-item {
    margin: 0;
    width: 100%;
  }

  .ant-btn {
    padding: 0;
    width: 49px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fields-loader-holder {
  display: flex;
  padding: 4px 0 10px;
}

.fields-loader {
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #1677ff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.disconnect-button {
  top: 0;
  --x-pos: 25%;
  position: absolute;
  right: var(--x-pos);
}

.disconnect-modal-body {
  font-family: Inter, sans-serif;

  > h3 {
    margin-bottom: 8px;
  }

  > p {
    margin-bottom: 20px;
  }
}

.disconnect-modal {
  .ant-modal-content {
    padding: 20px;
  }

  .ant-modal-close {
    top: 10px;
    right: 10px;
  }

  .ant-modal-footer {
    display: flex;

    > button {
      width: 100%;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
