.main-holder {
  gap: 24px;
  margin: auto;
  width: 600px;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.main-header {
  display: flex;
  align-items: flex-start;
}

.main-title {
  margin-right: auto;
}

.main-title h1 {
  font-size: 28px;
}

.main-title p {
  margin-top: 8px
}
