.event-nested-form {
  gap: 25px;
  display: flex;
  padding: 24px;
  overflow: auto;
  max-height: 368px;
  background: white;
  flex-direction: column;
  transition: max-height 300ms;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #F0F0F0;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.closed {
    border: none;
    max-height: 0;
    padding: 0 24px;
  }

  .ant-form-item-label {
    > label {
      &:after {
        content: '' !important;
      }
    }
  }
}

.nested-form-item {
  margin: 0;
  position: relative;

  &.ant-form-item-has-error {
    .ant-select {
      border-radius: 8px;
      border: 1px solid #ff4d4f;
    }

    .ant-form-item-explain-error {
      padding: 0;
    }
  }

  &:not(:last-child) {
    &:before {
      left: 0;
      width: 100%;
      content: '';
      bottom: -12px;
      position: absolute;
      border-bottom: 1px solid #F0F0F0;
    }
  }
}

.nested-form-item {
  > .ant-form-item-row {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .ant-form-item-label {
    display: flex;

    > label {
      margin-inline-end: auto;
      font-family: Inter, sans-serif !important;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > * {
      width: 236px;
    }
  }
}

.collapse-button-holder {
  display: flex;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #F0F0F0;

  > button {
    width: 100%;
    border: none;
  }
}